import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignUpFilmakers from '../SignUp/filmakers';
import SignInPage from '../SignIn';
import SignOutFilmakers from '../SignOut/filmakers';
import FilmsFormPage from '../FilmsForm';
import PasswordForgetPage from '../PasswordForget';
import FilmsListPage from '../FilmsList';
import FilmsTopPage from '../FilmsTop';
import FilmsSelectedPage from '../FilmsSelected';
import FilmsViewPage from '../FilmsView';
import FilmsEditPage from '../FilmsEdit';
import FilmsStuffPage from '../FilmsSelectedStuff';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import DashboardPage from '../Dashboard';
import SessionListPage from '../SessionList';
import SessionFormPage from '../SessionAdd';
import SessionEditPage from '../SessionEdit';


import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import * as U from '../../constants/utilities';

import { withAuthentication } from '../Session';
import { object } from 'underscore';

//<Route exact path={ROUTES.DASHBOARD + '/:type?'} component={DashboardPage} />
const App = (props) => (
  <Router>
          <Navigation />
        <div className='container' style={{ padding: '0', maxWidth: '1260px', margin: '0 auto'  }}>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          { U.isUserAnonymous() && (
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          )}
          <Route path={ROUTES.SIGN_OUT} component={SignOutFilmakers} />
          { CONFIG.SIGNUP_NEW_USERS && (
          <Route path={ROUTES.SIGN_UP + '/:role/:mail'} component={SignUpPage} />
          )}
          <Route
            path={ROUTES.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route exact path={ROUTES.FORM_LLARGS} component={FilmsFormPage} />
          <Route exact path={ROUTES.FORM_CURTS} component={FilmsFormPage} />
          <Route exact path={ROUTES.FORM_DOCS} component={FilmsFormPage} />
          <Route exact path={ROUTES.FORM_CENTRES} component={FilmsFormPage} />
          <Route exact path={ROUTES.FORM_VIDEOCLIPS} component={FilmsFormPage} />
          <Route exact path={ROUTES.FORM_YOUNG} component={FilmsFormPage} />
          <Route exact path={ROUTES.FORM_PITCHS} component={FilmsFormPage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ITEM + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:uid'} component={FilmsViewPage} />
          <Route path={ROUTES.CHOICE + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:curs(tots|primaria|secundaria)?/:view(normal|email|premsa|material)?'} component={FilmsSelectedPage} />
          <Route exact path={ROUTES.LIST} component={DashboardPage} />
          <Route path={ROUTES.SIGN_UP_FILMAKERS + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:year/:uid'} component={SignUpFilmakers} />
          <Route path={ROUTES.ITEM_STUFF + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:year/:uid'} component={FilmsStuffPage} />
          { (!U.isUserAnonymous() && !U.isUserJury()) ? (    
          <>
            <Route path={ROUTES.HOME} render={() => (<Redirect to={ROUTES.DASHBOARD} />)} />
            <Route path={ROUTES.ITEM_EDIT + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:uid'} component={FilmsEditPage} />
            <Route exact path={ROUTES.LIST + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:curs(tots|primaria|secundaria)?/:view(normal|email|export)?'} component={FilmsListPage} />
            <Route exact path={ROUTES.SESSION_LIST} component={SessionListPage} />
            <Route path={ROUTES.SESSION_EDIT + '/:uid'} component={SessionEditPage} />
            <Route exact path={ROUTES.FORM_SESSIONS} component={SessionFormPage} />
            <Route path={ROUTES.TOP + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove|pitchs)/:curs(tots|primaria|secundaria)?/:role(admin|comite|oficial|jove|centres)?'} component={FilmsTopPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
          </>
          ) : (
            <>
              <Route path={ROUTES.HOME} render={() => (<Redirect to={ROUTES.CHOICE + '/llistat'} />)} />
            </>
          )}
    </div>
  </Router>
);

export default withAuthentication(App);
