import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import { Rating } from 'react-simple-star-rating'
import { FaEnvelope, FaPen, FaPhone } from 'react-icons/fa';

class FilmsListContactExport extends Component {
  constructor(props) {
    super(props);


    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }

  getContactPhone(phone) {
    let tempPhone = phone;
    tempPhone.slice(0,2) === '34' ? tempPhone.slice(2) : phone
    
    return tempPhone 
  }

  render () {
    const { film, filmType } = this.state
    return (
      <>
        { U.isUserCommittee() && film.isSelectable && (
        <Col className='table-list' xs={12}>
          <Row>
            {CONFIG.CONTACT_M.map((modifier, key) => (
            <>
            { (film['contactEmail' + modifier] || film['contactPhone' + modifier]) && ( 
            <>
            {film['contactEmail' + modifier]},{film['contactName' + modifier]},{film['contactRole' + modifier].replace(',','')},{CONFIG.ACTIVE_EDITION} 
              
            </>
              )}
            </>
            ))}
            
          </Row>
        </Col>
        )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsListContactExport);