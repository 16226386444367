import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  VictoryPie,
  VictoryTheme,
  VictoryLabel
} from "victory";

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import FilmsListItemDefault from '../FilmsListItem';
import FilmsListItemMails from '../FilmsListContacts';
import FilmsListItemMailsExport from '../FilmsListContacts/export';


import FilmsListFilters from '../FilmsListFilters';
import Loading from '../Loading';
import { Container } from 'react-bootstrap';
import { PiWarningDiamondFill } from 'react-icons/pi';
import { FaFilm } from 'react-icons/fa';

let filmType = '';
let view = ''
let FilmsListItem = FilmsListItemDefault;


class FilmListPage extends Component {
  constructor(props) {
    super(props);

    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    view = props.match.params.view && U.isUserCommittee() ? props.match.params.view : 'normal';

    switch( props.match.params.view) {
      case 'email':
        FilmsListItem = U.isUserCommittee() ? FilmsListItemMails : FilmsListItemDefault;
      break;
      case 'export':
        FilmsListItem = U.isUserCommittee() ? FilmsListItemMailsExport : FilmsListItemDefault;
      break;
      default:
        FilmsListItem = FilmsListItemDefault;
    }
    this.state = {
      type: null,
    }

    
  }
  render () {
    return (
        <Films />
    )
  }
}

const FilmList = ({ films }) => (
  <Row className='film-list mx-auto p-0' xs={1} md={view === 'normal' ? 3 : 1} lg={ view === 'normal' ? 4 : 1}>
    {films.map(film => (
      (U.isUserAdmin() || film.isSelectable) && (
      <Col key={film.uid}>
        <FilmsListItem film={film} filmType={filmType} />
      </Col>
        )
    ))}
  </Row>
);

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      films: [],
      API: null,
      VictoryTheme: VictoryTheme.material

    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.filmTypeName = 'JOVES';
      break;
      case 'pitchs':
        this.state.API = this.props.firebase.pitchs();
        this.filmTypeName = 'PITCHS';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.films();
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }


  UpdateFilmList() {

    const scopeKeeper = this;
    
    this.setState({ loading: true });    

    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        // convert messages list from snapshot

        let filmListFiltered = {};
        switch(window.location.pathname.split('/')[3]) {
          case 'secundaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' ) } );
          break;
          case 'primaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
          break;
          default:
            filmListFiltered = filmList;
          break;
        }

        this.setState({
          films: filmListFiltered.reverse(),
          loading: false,
        });

        this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/').once('value', snapshotUserRanks => {
          if (snapshotUserRanks.val()) {

            const userRanks = Object.entries(snapshotUserRanks.val()).map((e) => ( { [e[0]]: e[1] } ));

            const myArrayFiltered = filmListFiltered.filter((el) => {
              return userRanks.some((f) => {
                return Object.keys(f)[0] === el.uid;
              });
            });
            scopeKeeper.setState({ watched: myArrayFiltered.length })
          }
    

          
        })


      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.type = 'LLARGS';
        break;
    }
    this.UpdateFilmList();
  }

  UNSAFE_componentWillReceiveProps() {
    this.UpdateFilmList();
  }
 
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading, type, VictoryTheme, watched } = this.state;
    return (
      <div>
        {films ? (
          <React.Fragment>
            { loading ? (
              <Loading />
            ) : (
            <Container>
              <Row>
              <Col xs className='pb-0'>
                  <h2 className='my-2'>{ this.filmTypeName ?  MESSAGES[this.filmTypeName].charAt(0).toUpperCase() : ''}{ MESSAGES[this.filmTypeName] ? MESSAGES[this.filmTypeName].slice(1) : '' }<br />
                  <small>{ MESSAGES[this.filmTypeName + '_FORM_DESC'] }</small>
                  </h2>
                  <FilmsListFilters urlBase={ ROUTES.LIST_CENTRES } filmType={type} filters={['view','school','export'] } view={ view } />
                </Col>
                <Col xs={5} lg={2}>
                  <svg viewBox="0 0 400 400">
                      <VictoryPie
                        theme={VictoryTheme}
                        standalone={false}
                        width={400}
                        height={400}
                        innerRadius={68}
                        labelRadius={95}
                        data={[
                          { x: ((this.getFilmsByIsSelectable(films, true).length) - watched) , y: ((this.getFilmsByIsSelectable(films, true).length) - watched), fill: 'red' },
                          { x: watched > 0 ? watched  : ' ' , y: watched, fill: 'green' },

                        ]}
                        style={{
                          data: {
                              fill: ({datum}) => datum.fill
                          },
                      }}
                      />
                      <VictoryLabel
                        textAnchor="middle"
                        style={{ fontSize: 50 }}
                        x={200}
                        y={200}
                        text={(this.getFilmsByIsSelectable(films, true).length)}
                    />
                  </svg>
                </Col>

                </Row>
                <Row>
                <Col sm lg>
                <Container className='card bg-light pt-1 mb-2 text-center'>
                  <Row>
                    <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Acceptats:</small></dt>
                        <dd className="h1 text-muted">{this.getFilmsByIsSelectable(films, true).length}</dd>
                      </dl>
                    </Col>
                    <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-success'>Evaluats:</small></dt>
                        <dd className="h1 text-success">{watched}</dd>
                      </dl>
                    </Col>
                    <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-danger'>Pendents:</small></dt>
                        <dd className="h1 text-danger">{((this.getFilmsByIsSelectable(films, true).length) - watched)}</dd>
                      </dl>
                    </Col>
                  </Row>
                  </Container>
                { U.isUserAdmin() &&(
                  <Container className='pt-0 mb-2 text-center'>
                  <Row>
                  <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Rebuts:</small></dt>
                        <dd className="h3 text-muted">{films ? films.length : '0'}</dd>
                      </dl>
                    </Col>

                      <Col>
                        <dl className='mb-0'>
                          <dt><small className='text-muted'>Revisió:</small></dt>
                          <dd className="h3 text-muted">{this.getFilmsByIsSelectable(films, undefined).length}</dd>
                        </dl>
                      </Col>
                      <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Rebutjats:</small></dt>
                        <dd className="h3 text-muted">{this.getFilmsByIsSelectable(films, false).length}</dd>
                      </dl>
                    </Col>

                  </Row>
                  </Container>
                    )}

              </Col>
            </Row>
              <Row>
                <FilmList films={films} />
              </Row>
              </Container>
            )}
          </React.Fragment>
        ) : (
          <Alert variant="warning"> <PiWarningDiamondFill /> No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = () => U.isUserCommittee();

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(FilmListPage);